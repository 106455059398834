/* Add this CSS to your stylesheet */

/* Default styles for larger screens */
.responsive-sidebar {
  display: none;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .responsive-sidebar {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
  }

  .responsive-sidebar.open {
    left: 0;
  }

  /* Adjust other styling properties for mobile view */
  /* ... */
}
.resposive-sidebar-menu {
  max-height: 450px;
  overflow-y: auto;
}
